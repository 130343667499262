// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bG_k4";
export var caseStudyBackground__lineColor = "bG_kW";
export var caseStudyHead__imageWrapper = "bG_kT";
export var caseStudyProjectsSection = "bG_k5";
export var caseStudyQuote__bgRing = "bG_k1";
export var caseStudySolution__ring = "bG_kZ";
export var caseStudySolution__ringThree = "bG_mj";
export var caseStudySolution__ringTwo = "bG_k0";
export var caseStudyVideo__ring = "bG_k8";
export var caseStudy__bgDark = "bG_kS";
export var caseStudy__bgLight = "bG_kR";
export var caseStudy__bgLightGray = "bG_mk";